.logos {
    background-image:url( "../../public/thisone.jpg");
    min-height: 2000px;
    background-repeat: no-repeat;
    background-size: cover;
    /*
    background-repeat: no-repeat;
    background-size: cover;
    position:relative;
    align-items: center;
    justify-content: center;
    align-content: center;*/
};
.text{
    border-color: black;
}
.boxed{
    justify-content: center;
    padding-top: 140px;
    padding-left: 100px;
}
.Ballon{
    font-family: sans-serif;
    text-align: center;
    padding-top: 0%;
    color: black;
  }
.ReactFloatingBalloons{
    background-color: aqua;
    font-size: large;

}
.But{
        position: 'fixed';
        text-align:center;
        bottom: 10px;
        width: 100%;
}
.end{
justify-content: center;
align-items: center;
font-family: cursive;
font-size: xx-large;
height: 100vh;
margin: 0;
display: flex;
background-color: #7f5a83;
background-image: linear-gradient(315deg, #d01717 0%, #1402b8 74%);
color: rgb(158, 251, 234);
}
.top{
    background-image: linear-gradient(315deg, #d01717 0%, #1402b8 74%);
    color: white;
}

/* slider */
.row {
  height: 250px;
  padding-bottom: 20px;
  color: white;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;


} 

/* slider track */
.left {
 
  display: flex;
  width: calc(250px*20);
    animation: scroll 15s linear infinite;
}

.right {
 
  display: flex;
  width: calc(250px*20);
    animation: scrolll 15s linear infinite;
}
.row_posters::-webkit-scrollbar {
  display: none; /*hides horizontal scroll bar*/
}
/* slide */
.row_poster {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  padding: 5px;
  perspective: 100px;max-width: 100%;
 


  
}

.row_poster img {
  width: 100%;
  
 
}
@keyframes scroll {
    0%{
        transform: translateX(calc(-250px*10));
    }
    100%{
        transform: translateX(calc(0));
    }
}

@keyframes scrolll {
  0%{
      transform: translateX(calc(0));
  }
  100%{
      transform: translateX(calc(-250px*10));
  }
}
.row_poster:hover {
  transform: scale(1.08);

}
.row_posters:hover {
  animation-play-state: paused ;
}
.row_posterLarge {
  max-height: 250px;
}
.row_posterLarge:hover {
  transform: scale(1.09);
  opacity: 1;
  
}

.logos{
   justify-self: start;
}
.navigation-bar {
    align-items: center;
    justify-content: center;
    display:flex;
    background-repeat: no-repeat;
    width: auto;
    background-size: cover;
    position: relative;
    color: whitesmoke;
    font-family: cursive;
};
.nav-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}
.ham-icon{
    display: none;
    
}
.lists{
    justify-content: end;
    height: 100%;
    display: flex;
    width: 60vw;
    text-align: center;
    margin-right: 2rem;
}

.items{
    list-style-type: none;
    padding: 0rem 1rem;
    float: right;
    justify-content: center;
    font-size: large;
    color: pink;
    font-family: cursive;
    
}
.textitems{
    color: whitesmoke;
    text-decoration: none;
    justify-content: center;
}
.ham-icon{
    display: none;
}


.nav-logos{
    height: 80px;
    width: 250px;
    margin-left: 20px;
    display: flex;
    justify-self: start;
    align-items: center;
}
@media screen and (max-width:900px){
    .nav-logos{
        display: none;
       
    }
    .navigation-bar {
        width: 100%;
        height: 80px;
        background: black;
        align-items: center;
        display:flex;
        
        
    };
    .nav-menu{
        display: flex;
        
    }
    .lists{
        display: flex;
        margin-left: 100px;
        
    }
    .items{
        font-size: medium;
        padding: 0rem .75rem;
        
    }}

.wheel-box {
    border: 1px solid green;
    display: flex;
    width: 300px;
    height: 300px;
  }
  .App{
    font-family: cursive;
  }
  .all{
    background-image: linear-gradient(315deg, #d01717 0%, #1402b8 74%);
  }
  .sec{
    background-image: linear-gradient(315deg, #d01717 0%, #1402b8 74%);
 
  }
  .second{
    background-image: linear-gradient(315deg, #d01717 0%, #1402b8 74%);
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
    width:100%;
  height:auto; 
  box-sizing:border-box;
  overflow:hidden;

  }
  